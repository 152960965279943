import { ListGroup } from 'react-bootstrap'
import { styled } from 'styled-components'
import { over } from 'utils'

export const SearchOverlayWrapper = styled.div<{ $hasEnoughHeight: boolean }>`
	position: relative;
	width: 100%;
	max-width: 800px;
	min-height: 50px;
	max-height: 600px;
	top: 5px;
	background-color: ${(props) => props.theme.colours.primary.white};
	box-shadow: ${(props) => props.theme.shadows.xxl};
	border: 1px solid ${(props) => props.theme.colours.primary.greyShade};
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-content: center;
	z-index: 1;
	${over('sm')} {
		position: ${(props) => props.$hasEnoughHeight && 'absolute'};
	}
	.paging-info {
		font-size: 16px !important;
		padding: 0;
		${over('sm')} {
			padding: 1rem 1.2rem;
		}
	}
`

export const SearchResultsWrapper = styled(ListGroup)`
	position: relative;
	overflow-y: auto;
	border-radius: 3px;

	em {
		font-style: normal;
		border-radius: 3px;
		padding: 0.2rem 0;
		font-weight: bold;
	}
	.active {
		font-weight: 600;
		em {
			background-color: ${(props) => props.theme.colours.primary.white};
			font-style: normal;
			font-weight: 600;
			border-radius: 3px;
		}
	}
	.table-headers {
		font-size: 14px;
		font-weight: 600;
		color: #77777b;
		padding: 0.7rem 1.4rem;
		&:hover {
			background-color: none !important;
		}
	}

	.sui-search-error {
		display: none !important;
	}
`

export const CheckBoxWrapper = styled.button`
	label {
		padding-left: 0 !important;
		color: ${(props) => props.theme.colours.primary.black};
	}
	.form-check-input {
		border-color: ${(props) => props.theme.colours.primary.black};
		border-width: 2px;
	}
	.form-check-input:checked {
		background-color: ${(props) => props.theme.colours.primary.blue};
		border-color: ${(props) => props.theme.colours.primary.black};
		font-weight: bold;
	}
`

export const MoreResultsButton = styled.button`
	text-align: left;
	margin: 0;
	padding: 1rem;
	background-color: ${(props) => props.theme.colours.primary.blue};
	color: ${(props) => props.theme.colours.primary.white};
	border: 0;
	&:hover {
		opacity: 0.8;
		background-color: ${(props) => props.theme.colours.primary.blue};
	}
`
