import { styled } from 'styled-components'
import { getColor } from './utils'
import type { IProps } from './interface'

export const TagWrapper = styled.span<IProps>`
	font-size: ${(props) => props.theme.fontSizes.bodyText.sm};
	line-height: 23px;
	font-weight: 500;
	letter-spacing: 0.02em;
	height: auto;
	display: inline-block;
	transition: background 0.1s ease-out;
	background: ${(props) => getColor(props.theme, props.color)};
	padding: 0.1rem 0.6rem;
	border: 0;
	border-radius: 3px;
	cursor: default;
`
