import { ReactNode, useLayoutEffect } from 'react'
import { getCodeSystem, getCodeSystemEndpoint, paths } from 'utils'
import { useLocation } from 'react-router-dom'
import { useStoreContext } from 'context/StoreProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Footer, Header } from './common'
import { styled } from 'styled-components'
import { Container } from 'react-bootstrap'

interface LayoutProps {
	children: ReactNode
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: false,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
		},
	},
})

const AppWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin: 0 auto;
`

const Layout = ({ children }: LayoutProps) => {
	const { pathname } = useLocation()

	const { actions } = useStoreContext()

	const { updateCodeSystem } = actions

	useLayoutEffect(() => {
		if (pathname === getCodeSystemEndpoint(pathname)) {
			updateCodeSystem(getCodeSystem(pathname) as string)
		}
	}, [pathname, updateCodeSystem])

	return (
		<AppWrapper>
			<QueryClientProvider client={queryClient}>
				<Header />
				<Container
					as={'main'}
					id='main-content'
					className={`flex-grow-1 overflow-y d-flex flex-column px-xl-5`}
				>
					{children}
				</Container>
				{pathname === paths.main ? <Footer /> : null}
			</QueryClientProvider>
		</AppWrapper>
	)
}

export default Layout
