import { ListGroup, Row } from 'react-bootstrap'
import { styled } from 'styled-components'
import { over } from 'utils'

export const SearchResultBar = styled(ListGroup)<{ $isAdm: boolean }>`
	position: relative;
	width: ${({ $isAdm }) => ($isAdm ? 'max-content' : '100%')};

	${over('xxl')} {
		width: unset;
	}

	button:nth-child(even) {
		background-color: #f2f6fa;
	}
`

export const TableRow = styled(Row)`
	:last-child {
		justify-content: flex-end;
	}
`

export const ResultViewWrapper = styled(ListGroup.Item)<{
	$isDisabled: boolean
}>`
	border-bottom: 1px solid ${(props) => props.theme.colours.primary.aqua} !important;
	border: none;
	padding: 0.8rem 0rem;
	color: ${(props) => props.theme.colours.primary.black};
	opacity: ${(props) => (props.$isDisabled ? '0.8' : '1')};
	cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
	width: 100%;

	${over('md')} {
		padding: 0.8rem 0.5rem;
	}

	&:last-child {
		border-radius: unset;
	}
	&:hover {
		color: ${(props) => props.theme.colours.primary.blue};
		// z-index: 1;
	}

	.icon-blue {
		color: ${(props) => props.theme.colours.primary.blue};
	}
	.border-left {
		border-left: 2px solid ${(props) => props.theme.colours.primary.blue};
		padding: 0.2rem 0;
	}
`

export const TableHeaderContainer = styled.div<{
	$topValue: string
	$isAdm?: boolean
}>`
	position: sticky !important;
	top: 0;
	// z-index: ${({ $isAdm }) =>
		$isAdm ? 1 : 2}; //TODO: Figure out why? Improve sticky
	z-index: 2;
	background-color: ${(props) => props.theme.colours.primary.white};
	border-bottom: 2px solid ${(props) => props.theme.colours.primary.blue};
	${over('md')} {
		padding: 0 0.5rem;
	}
`

export const TableHeader = styled(Row)`
	display: flex;
	padding: 0.5rem 0rem;
	font-weight: 600;
`
