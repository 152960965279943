import { Nav } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { paths } from 'utils'
import { CodeSystemTabWrapper } from './style'
import {
	isClinicalCodesystem,
	isSmallLabCodesystem,
} from 'components/search/buckets/utils'

const CodesystemTabNav = () => {
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const {
		icd10,
		icpc2,
		nkpk,
		phbu,
		anatomisk,
		tekstligeresultatverdier,
		undersokelsesmetode,
		provemateriale,
	} = paths

	const doNavigate = (path: string) => {
		if (pathname.includes(path)) return

		const params = new URLSearchParams(window.location.search)
		const q = params.get('q')
		const search = q ? `?q=${q}` : ''
		navigate(`${path}${search}`)
	}

	// const headerHeight = document.getElementById('site-header')?.clientHeight || 0

	return (
		<CodeSystemTabWrapper
			// $topValue={headerHeight + 1}
			id={`codesystem-tab-nav`}
		>
			<Nav className='d-flex flex-row flex-nowrap gap-1 text-nowrap'>
				{isClinicalCodesystem(pathname) && (
					<>
						<Nav.Item
							aria-label={`${t('general.navigateTo')} ${t(
								'codesystems.clinicalCodework.icd10.name'
							)}`}
						>
							<Nav.Link
								active={pathname.includes(icd10)}
								onClick={() => doNavigate(`${icd10}/chapter`)}
							>
								{t('codesystems.clinicalCodework.icd10.name')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item
							aria-label={`${t('general.navigateTo')} ${t(
								'codesystems.clinicalCodework.icpc2.name'
							)}`}
						>
							<Nav.Link
								active={pathname.includes(icpc2)}
								onClick={() => doNavigate(`${icpc2}/chapter`)}
							>
								{t('codesystems.clinicalCodework.icpc2.name')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item
							aria-label={`${t('general.navigateTo')} ${t(
								'codesystems.clinicalCodework.nkpk.shortName'
							)}`}
						>
							<Nav.Link
								active={pathname.includes(nkpk)}
								onClick={() => doNavigate(`${nkpk}/chapter`)}
							>
								{t('codesystems.clinicalCodework.nkpk.shortName')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item
							aria-label={`${t('general.navigateTo')} ${t(
								'codesystems.clinicalCodework.phbu.name'
							)}`}
						>
							<Nav.Link
								active={pathname.includes(phbu)}
								onClick={() => doNavigate(`${phbu}/chapter`)}
							>
								{t('codesystems.clinicalCodework.phbu.name')}
							</Nav.Link>
						</Nav.Item>
					</>
				)}
				{isSmallLabCodesystem(pathname) && (
					<>
						<Nav.Item
							aria-label={`${t('general.navigateTo')} ${t(
								'codesystems.labCodework.provemateriale'
							)}`}
						>
							<Nav.Link
								active={pathname.includes(provemateriale)}
								onClick={() => navigate(provemateriale)}
							>
								{t('codesystems.labCodework.provemateriale')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item
							aria-label={`${t('general.navigateTo')} ${t(
								'codesystems.labCodework.anatomisk'
							)}`}
						>
							<Nav.Link
								active={pathname.includes(anatomisk)}
								onClick={() => navigate(anatomisk)}
							>
								{t('codesystems.labCodework.anatomisk')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item
							aria-label={`${t('general.navigateTo')} ${t(
								'codesystems.labCodework.tekstligeresultatverdier'
							)}`}
						>
							<Nav.Link
								active={pathname.includes(tekstligeresultatverdier)}
								onClick={() => navigate(tekstligeresultatverdier)}
							>
								{t('codesystems.labCodework.tekstligeresultatverdier')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item
							aria-label={`${t('general.navigateTo')} ${t(
								'codesystems.labCodework.undersokelsesmetode'
							)}`}
						>
							<Nav.Link
								active={pathname.includes(undersokelsesmetode)}
								onClick={() => navigate(undersokelsesmetode)}
							>
								{t('codesystems.labCodework.undersokelsesmetode')}
							</Nav.Link>
						</Nav.Item>
					</>
				)}
			</Nav>
		</CodeSystemTabWrapper>
	)
}

export default CodesystemTabNav
