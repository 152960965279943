import {
	faChevronRight,
	faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ListGroup as BSListGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LinkGroupProps, LinkListProps } from './interface'
import { ListGroupWrapper } from './style'
import { scrollToTop } from 'utils'

const LinkGroup = ({
	linkList,
	internalVariant = 'linkgroup',
	showDescription = true,
	onClick,
}: LinkGroupProps) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const handleOnClick = (link: string) => {
		if (onClick) {
			onClick()
		}
		if (link.startsWith('http')) {
			window.open(link, '_blank')
		} else {
			navigate(link)
			scrollToTop()
		}
	}

	const isOldLink = (item: LinkListProps) =>
		item.text === 'codesystems.clinicalCodework.oldVersion.name'
	return (
		<ListGroupWrapper variant='flush' $internalVariant={internalVariant}>
			{linkList.map((item, i) => (
				<BSListGroup.Item
					action
					key={i}
					onClick={() => handleOnClick(item.link)}
					className={`d-flex justify-content-between align-items-center ps-2 py-4 ${
						isOldLink(item) ? 'bg-yellow' : ''
					}`}
				>
					<div>
						<div
							style={{ display: isOldLink(item) ? 'inline-block' : 'default' }}
							className='fs-5 text-blue fw-bold'
						>
							{t(item.text as keyof typeof t)}
						</div>
						{'	'}
						{isOldLink(item) && (
							<div style={{ display: 'inline-block' }}>
								<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
							</div>
						)}
						{showDescription && item.description && (
							<div className={`fs-6 pe-2 pt-1 text-gray-color`}>
								{t(item.description as keyof typeof t)}
							</div>
						)}
					</div>

					{internalVariant === 'linkgroup' && (
						<FontAwesomeIcon icon={faChevronRight} />
					)}
				</BSListGroup.Item>
			))}
		</ListGroupWrapper>
	)
}

export default LinkGroup
