import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NodeProps } from './interface'
import { StyledNode } from './style'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const Node = ({
	level,
	isExpanded,
	handleNavigate,
	item,
	innerRef,
	isActive,
	children,
}: NodeProps) => {
	const { t } = useTranslation()
	return (
		<StyledNode
			$level={level}
			$isActive={isActive}
			$isExpanded={isExpanded}
			ref={innerRef}
			id={`${item.id}-node`}
		>
			<div className='d-flex align-items-center gap-2'>
				{level === 0 && (
					<button
						onClick={handleNavigate}
						onKeyDown={(event) => {
							if (event.key === 'Enter' || event.key === ' ') {
								handleNavigate()
							}
						}}
						className='btn'
						tabIndex={0}
						aria-label={`${t('general.open')} ${item.id} ${item.name}`}
					>
						<span className='expand-button'>
							{isExpanded ? (
								<FontAwesomeIcon
									icon={faMinus}
									size='sm'
									width={'12px'}
									height={'12px'}
								/>
							) : (
								<FontAwesomeIcon icon={faPlus} size='sm' />
							)}
						</span>
					</button>
				)}

				<span
					className={`single-item text-nowrap ${isActive ? 'active' : ''}`}
					onClick={handleNavigate}
					onKeyDown={(event) => {
						if (event.key === 'Enter' || event.key === ' ') {
							handleNavigate()
						}
					}}
					role='button'
					tabIndex={0}
					aria-label={`${t('general.navigateTo')} ${item.id} ${item.name}`}
				>
					{item.id.startsWith('S') ? null : (
						<span className='code'>{item.id}</span>
					)}

					<span className='term'>{item.name}</span>
				</span>
			</div>
			{children}
		</StyledNode>
	)
}

export default Node
