import { Col, Container } from 'react-bootstrap'
import { styled } from 'styled-components'
import { over } from 'utils'

export const LayoutWrapper = styled(Container)`
	padding: 0;
	display: flex;
	flex-direction: column;

	${over('lg')} {
		flex-direction: row;
		column-gap: 1rem;
	}
	.search-bar {
		padding-bottom: 3rem;
		padding-top: 1rem;
		width: 100%;
		background-color: white;
		position: sticky;
	}
`

export const SidebarContent = styled(Col)<{
	$isAdm: boolean
	$size: string
	$isOpen: boolean
}>`
	padding: 0 10px; // SJEKK ALLE STEDER DENNE PÅVIRKER. FIX/TODO
	overflow: auto;
	display: ${({ $isOpen }) =>
		$isOpen !== undefined
			? $isOpen
				? 'block'
				: 'none'
			: 'block'}; // Bruker verdien hvis den er satt, ellers true

	height: ${({ $isAdm }) =>
		$isAdm
			? 'calc(100dvh - 230px)'
			: 'calc(100dvh - 270px)'}; //denne må testes mot de ulike viduenen.

	height: ${({ $size }) =>
		$size === 'lg'
			? 'calc(100dvh - 200px)'
			: $size === 'md'
			? 'calc(100dvh - 230px)'
			: 'calc(100dvh - 270px)'};
				
}
`
