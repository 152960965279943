import { useState, useEffect, useRef, useLayoutEffect } from 'react'
import Content from './Content'
import { useStoreContext } from 'context/StoreProvider'
import { ErrorMessage, Loading, ScrollChapterWrapper } from 'components/common'
import { Chapter, ClinicalParagraph } from './interface'
import ChapterHandler from './ChapterHandler'

const ChapterResults = () => {
	const { state } = useStoreContext()
	const { codeSystem, code, scrollRef } = state
	const chapterHandler = useRef(new ChapterHandler(codeSystem!, code!))
	const [chapterData, setChapterData] = useState<Chapter | null>(null)
	const [error, setError] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const containerRef = useRef<HTMLDivElement | null>(null)
	const initialized = useRef(false)

	const initialFetch = async () => {
		setLoading(true)
		try {
			const data = await chapterHandler.current.initalizeChapterData()
			if (data) {
				return data
			}
		} catch (e) {
			setError('Error fetching data: ' + e)
			return null
		} finally {
			setLoading(false)
		}
	}

	const requestMoreData = async (direction: 'next' | 'prev') => {
		setLoading(true)
		try {
			const result = await chapterHandler.current.fetchMoreData(direction)
			if (result) {
				setChapterData(result)
			}
		} catch (e) {
			setError('Error fetching data: ' + e)
		} finally {
			setLoading(false)
		}
	}

	const shouldRequestPreviousPage = () => {
		// Do nothing if we cannot find the scroll container
		const scrollElement = containerRef.current?.parentElement
		if (!scrollElement) return false
		// Request previous page if it exists and scroll is close to top
		// 'window.innerHeight * 5' is for cases where people scroll very fast
		if (
			scrollElement.scrollTop < window.innerHeight * 5 &&
			chapterHandler.current.morePagesAvailable('prev')
		) {
			return true
		}
		return false
	}

	const shouldRequestNextPage = () => {
		// Do nothing if we cannot find the scroll container
		const scrollElement = containerRef.current?.parentElement
		if (!scrollElement) return false

		const { scrollTop, scrollHeight, clientHeight } = scrollElement
		// Request next page if it exists and scroll is close is to bottom
		const scrollDistanceRemaining = scrollHeight - scrollTop - clientHeight
		if (
			scrollDistanceRemaining <= window.innerHeight * 2 &&
			chapterHandler.current.morePagesAvailable('next')
		) {
			return true
		}
		return false
	}

	const handleScroll = () => {
		const scrollElement = containerRef.current?.parentElement
		if (!scrollElement) return
		if (shouldRequestPreviousPage()) {
			scrollElement.removeEventListener('scroll', handleScroll)
			requestMoreData('prev')
		} else if (shouldRequestNextPage()) {
			scrollElement.removeEventListener('scroll', handleScroll)
			requestMoreData('next')
		}
	}

	useEffect(() => {
		setChapterData(null)
		chapterHandler.current = new ChapterHandler(codeSystem!, code!)
		removeEventListener('scroll', handleScroll)
		initialized.current = false
		if (code && codeSystem && !loading) {
			initialFetch().then((data) => {
				if (data) {
					setChapterData(data)
				}
			})
		}
	}, [code, codeSystem])

	useLayoutEffect(() => {
		if (!initialized.current) {
			const element = scrollRef.current[code as string]
			if (element) {
				element.scrollIntoView()
				initialized.current = true
			} else {
				return
			}
		}

		containerRef.current?.parentElement?.addEventListener(
			'scroll',
			handleScroll
		)
		return () => {
			containerRef.current?.parentElement?.removeEventListener(
				'scroll',
				handleScroll
			)
		}
	})

	return (
		<ScrollChapterWrapper
			id='chapter-content'
			className='d-flex flex-column gap-4 p-0 pt-lg-4 px-lg-4 bg-white'
			tabIndex={0}
			ref={containerRef}
		>
			{loading && <Loading />}
			{error && <ErrorMessage errorResponse={error} />}
			{chapterData?.data.map((paragraph: ClinicalParagraph) => (
				<Content
					key={paragraph.codeValue}
					paragraph={paragraph}
					codeSystem={codeSystem as string}
					activeCode={code === paragraph.codeValue}
					innerRef={(contentRef: HTMLDivElement | null) =>
						(scrollRef.current[paragraph.codeValue] = contentRef)
					}
				/>
			))}
		</ScrollChapterWrapper>
	)
}

export default ChapterResults
