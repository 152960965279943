import { PagingInfo, ResultsPerPage } from '@elastic/react-search-ui'
import { PagingInfoView, ResultsPerPageView } from 'components/common'
import { isSmallLabCodesystem } from 'components/search/buckets/utils'
import { Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { MetaBarWrapper } from './style'

const MetaInfoBar = () => {
	const { pathname } = useLocation()

	return isSmallLabCodesystem(pathname) ? (
		<MetaBarWrapper
			className={`align-items-end result-meta-bar pt-3 pt-lg-1`}
			id='result-meta'
		>
			<PagingInfo view={PagingInfoView} />
		</MetaBarWrapper>
	) : (
		<MetaBarWrapper className='align-items-end pt-1' id='result-meta-bar'>
			<Col className='p-0'>
				<PagingInfo view={PagingInfoView} />
			</Col>

			<Col xs='auto' className='p-0'>
				<ResultsPerPage
					view={ResultsPerPageView}
					options={[10, 15, 20, 30, 40, 50, 100]}
				/>
			</Col>
		</MetaBarWrapper>
	)
}

export default MetaInfoBar
