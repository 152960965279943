import React, { useState } from 'react'
import GeneralSearchTest from 'components/search/buckets/GeneralSearchTest'

const GeneralSearchTestResults = () => {
	// State for each input field
	const [nameWeight, setNameWeight] = useState(3)
	const [nameSynonymWeight, setNameSynonymWeight] = useState(3)
	const [nameSingleSynonymWeight, setNameSingleSynonymWeight] = useState(0)
	const [inclusionWeight, setInclusionWeight] = useState(1)
	const [precision, setPrecision] = useState(3)
	const [codeValueWeight, setCodeValueWeight] = useState(0)

	// Function to validate and set input values
	const setInputValue = (setter: any) => (event: any) => {
		const value = Math.max(0, Math.min(1000, Number(event.target.value)))
		setter(value)
	}

	const inputStyle = {
		width: '50px',
		marginRight: '10px',
		display: 'inline',
	}

	return (
		<>
			<div style={{ width: '100%' }}>
				<label htmlFor='precision'>Presisjon</label>
				<input
					id='precision'
					type='number'
					value={precision}
					onChange={setInputValue(setPrecision)}
					min='1'
					max='11'
					style={inputStyle}
				/>
				<label htmlFor='nameWeight'>Kodetekst</label>
				<input
					id='nameWeight'
					type='number'
					value={nameWeight}
					onChange={setInputValue(setNameWeight)}
					min='0'
					max='1000'
					style={inputStyle}
				/>
				<label htmlFor='nameSynonymWeight'>Kodetekst med synonymer</label>
				<input
					id='nameSynonymWeight'
					type='number'
					value={nameSynonymWeight}
					onChange={setInputValue(setNameSynonymWeight)}
					min='0'
					max='1000'
					style={inputStyle}
				/>
				<label htmlFor='inclusionWeight'>Inklusjon</label>
				<input
					id='inclusionWeight'
					type='number'
					value={inclusionWeight}
					onChange={setInputValue(setInclusionWeight)}
					min='0'
					max='1000'
					style={inputStyle}
				/>
				<label htmlFor='codeValue'>Kodeverdi</label>
				<input
					id='codeValue'
					type='number'
					value={codeValueWeight}
					onChange={setInputValue(setCodeValueWeight)}
					min='0'
					max='1000'
					style={inputStyle}
				/>
			</div>

			<GeneralSearchTest
				precision={precision}
				nameWeight={nameWeight}
				nameSynonymWeight={nameSynonymWeight}
				nameSingleSynonymWeight={nameSingleSynonymWeight}
				inclusionWeight={inclusionWeight}
				codeValueWeight={codeValueWeight}
			/>
		</>
	)
}

export default GeneralSearchTestResults
