const resultFields = {
	subject_area: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	compact_code: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	code_tip: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	subterm: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	code: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	code_nlk: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	notes: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	name_english: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	source_code_system: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	exclusion: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	valid_from: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	name_norwegian: {
		snippet: {
			size: 1000,
			fallback: true,
		},
		raw: {},
	},
	name_norwegian_nlk: {
		snippet: {
			size: 1000,
			fallback: true,
		},
		raw: {},
	},
	is_apat: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	star_code: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	last_changed_date: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	inclusion: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	valid_to: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	parent_code: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	property_type: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	definition: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	id: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	system_specification: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	group: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	sword_codes: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	short_definition: {
		snippet: {
			size: 700,
			fallback: true,
		},
		raw: {},
	},
	code_system: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	gender_specific_code: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	active: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	property_type_specification: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	replaced_by: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	non_specific_code: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	status_changed_date: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	text_max_60: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	secondary_subject_area: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	component: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	unit: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	system: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	additional_code: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	reportable_npr: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	additional_atc_code_recommended: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	status_text: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	component_specification: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
	snomed_synonyms: {
		snippet: {
			size: 200,
			fallback: true,
		},
		raw: {},
	},
}

export default resultFields
