import {
	ClearFilters,
	CustomFacet,
	CustomFilter,
	FilterTitle,
} from 'components/common'
import { SidebarFilterWrapper } from 'components/search/buckets/common'
import { useTranslation } from 'react-i18next'

const SnomedFilter = () => {
	const { t } = useTranslation()

	return (
		<SidebarFilterWrapper
			$isDesktop={false}
			className={`d-flex flex-column gap-3 pt-3 pt-lg-0
			}`}
		>
			<span className='d-flex flex-column gap-2 pt-0 pt-lg-2'>
				<FilterTitle
					hasBorderBottom={true}
					className='d-flex justify-content-between position-sticky top-0'
				>
					{t('nlkPage.filter.title')}
					<ClearFilters />
				</FilterTitle>
			</span>
			<div className='position-sticky top-0'>
				<CustomFacet
					className='d-flex flex-column gap-2'
					field='member_of_refsets'
					label='Referansesett'
					view={CustomFilter}
					filterType={'any'}
					isFilterable
					show={20}
				/>
			</div>
		</SidebarFilterWrapper>
	)
}

export default SnomedFilter
