import paths from '../constants/paths'
import codesystems from '../constants/codesystems'

/* Used to get the codesystem to set the filter in elastic */

const codeSystemMap: Record<string, string> = {
	[paths.icd10]: codesystems.icd10,
	[paths.icpc2]: codesystems.icpc2,
	[paths.nkpk]: codesystems.nkpk,
	[paths.phbu]: codesystems.phbu,
	[paths.anatomisk]: codesystems.anatomisk,
	[paths.norpat]: codesystems.norpat,
	[paths.provemateriale]: codesystems.provemateriale,
	[paths.tekstligeresultatverdier]: codesystems.tekstligeresultatverdier,
	[paths.undersokelsesmetode]: codesystems.undersokelsesmetode,
	[paths.adm]: codesystems.adm,
	[paths.nlk]: codesystems.nlk,
	[paths.snomed]: codesystems.snomedct,
}

const getCodeSystem = (pathname: string) => {
	for (const path in codeSystemMap) {
		if (pathname.includes(path)) {
			return codeSystemMap[path]
		}
	}
	return
}

export default getCodeSystem
