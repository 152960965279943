import { chapterPageSize, endpoints } from 'utils/constants'

type EndpointOptions = {
	additionalPath?: string
	useVersion?: boolean
	useChildren?: boolean
	useHierarchy?: boolean
	includeIcpc2b?: boolean
	includeInactive?: boolean
	isAdm?: boolean
	isSnomed?: boolean
	isChapterView?: boolean
	pageParam?: unknown
	download?: boolean
	fileType?: 'EXCEL' | 'XML' | 'JSON'
}

const getApiEndpoint = (
	codesystem: string,
	code?: string,
	options?: EndpointOptions
) => {
	let url
	const hasCode = code !== '' ? '/' + code : ''
	const queryParams = []

	if (options?.isAdm) {
		url = `${endpoints.apiAdmEndpointDev}${hasCode}`
	} else if (options?.isSnomed) {
		url = `${endpoints.apiSnomedEndpointDev}${hasCode}`
	} else {
		url = `${endpoints.apiEndpointDev}/${codesystem}${hasCode}`
	}

	/* To manually add options to endpoint */
	if (options?.additionalPath) {
		url += `/${options.additionalPath}`
	}

	if (options?.useChildren) {
		url += '/children'
	}

	if (options?.useHierarchy) {
		url += '/hierarchy'
	}

	if (options?.includeIcpc2b) {
		queryParams.push('includeIcpc2b=true')
	}

	if (options?.includeInactive) {
		queryParams.push('includeInactive=true')
	}

	if (options?.isChapterView) {
		url += `/chapter`
		queryParams.push(`pageSize=${chapterPageSize}`)

		if (options.pageParam) {
			queryParams.push(`pageNumber=${options.pageParam}`)
		}
	}

	if (options?.download) {
		url += `${endpoints.excelEndpoint}/${options.fileType}`
	}

	if (queryParams.length > 0) {
		url += `?${queryParams.join('&')}`
	}

	return url
}

export default getApiEndpoint
