import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import type { FilterType, FilterValue } from '@elastic/search-ui'
import { resultFields } from 'components/search/buckets/ElasticSearch/config/settings'
import { codesystems } from 'utils'

const searchKey = process.env.REACT_APP_ELASTIC_SEARCH_KEY
const engineName = process.env.REACT_APP_ELASTIC_CODESYSTEMS_ENGINE_NAME //'ehelse-kodeverk-ngram-synonym'
const endpointBase = process.env.REACT_APP_ELASTIC_ENDPOINT_BASE

export const generalConfig = (
	precision: number,
	nameWeight: number,
	nameSynonymWeight: number,
	nameSingleSynonymWeight: number,
	inclusionWeight: number,
	codeValueWeight: number
) => {
	return {
		apiConnector: new AppSearchAPIConnector({
			searchKey: searchKey as string,
			engineName: engineName as string,
			endpointBase: endpointBase as string,
		}),
		hasA11yNotifications: false,
		alwaysSearchOnInitialLoad: true,
		searchQuery: {
			precision: precision,
			search_fields: {
				name_norwegian: {
					weight: nameWeight,
				},
				snomed_synonyms: {
					weight: nameSynonymWeight,
				},
				code: {
					weight: codeValueWeight,
				},
				compact_code: {
					weight: 1,
				},
				code_system: {
					weight: 1,
				},
				code_values: {
					weight: 1,
				},
				inclusion: {
					weight: inclusionWeight,
				},
				notes: {
					weight: 1,
				},
				chapter_code: {
					weight: 1,
				},
			},
			filters: [
				{
					field: 'code_system',
					values: [
						codesystems.icd10,
						codesystems.phbu,
						codesystems.icpc2,
						codesystems.icpc2b,
						codesystems.nkpk,
						codesystems.nlk,
						codesystems.anatomisk,
						codesystems.tekstligeresultatverdier,
						codesystems.undersokelsesmetode,
						codesystems.norpat,
						codesystems.provemateriale,
					],
					type: 'any' as FilterType,
				},
				{
					field: 'active',
					values: ['true' as FilterValue],
					type: 'any' as FilterType,
				},
			],
			disjunctiveFacets: ['code_system', 'active', 'status_text', 'is_apat'],
			facets: {
				code_system: {
					type: 'value',
				},
				active: {
					type: 'value',
				},
				status_text: {
					type: 'value',
				},
				is_apat: {
					type: 'value',
				},
			},
			result_fields: resultFields,
		},
	}
}
