import { ErrorMessage, Heading, Link, Loading } from 'components/common'
import { Col, Row } from 'react-bootstrap'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import {
	capitalizeFirstLetter,
	externalUrls,
	fetchApi,
	getApiEndpoint,
} from 'utils'
import { SnomedResponse } from './interface'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { Wrapper } from './style'
import { isEnglish } from './utils'
import { MapsToTable, MemberOfRefsets, Term } from './common'
import { useState } from 'react'
import { SidebarContent } from 'components/search/buckets/SearchWithFilter/common/SearchResultLayout/style'

const SnomedResults = () => {
	const { codeValue } = useParams()

	const [fetchError, setFetchError] = useState<string>('')

	const { data, isError, isFetching } = useQuery<SnomedResponse, Error>({
		queryKey: ['snomed', codeValue],
		queryFn: () =>
			fetchApi(
				getApiEndpoint('snomed', codeValue as string, {
					isSnomed: true,
				}),
				setFetchError('404')
			),
		staleTime: 60000,
		retry: false,
		enabled: !!codeValue,
	})

	const termNorwegian = data?.termNorwegian

	return (
		<SidebarContent $size={'lg'}>
			<Wrapper>
				{isError && <ErrorMessage errorResponse={fetchError} />}
				{isFetching && <Loading />}
				{data && (
					<Row className='align-items-center p-0'>
						<Col className='p-0'>
							<Heading variant='h2' id='snomed-ct-heading'>
								{termNorwegian
									? capitalizeFirstLetter(termNorwegian)
									: data?.terms.find(isEnglish)?.preferredTerm}
							</Heading>
						</Col>
						<Col xs='auto' className='tag blue d-flex gap-1 align-items-center'>
							<Link
								text={data?.conceptId}
								icon={faExternalLink}
								iconRight
								isBlue={false}
								target='_blank'
								href={`${externalUrls.snomedctBrowser}/?perspective=full&conceptId1=${data?.conceptId}&edition=MAIN/SNOMEDCT-NO/REFSETS&release=&languages=no,en`}
							/>
						</Col>
					</Row>
				)}

				{data?.terms && <Term terms={data.terms} />}

				{data?.memberOfRefsets && data.memberOfRefsets.length > 0 && (
					<MemberOfRefsets memberOfRefsets={data.memberOfRefsets} />
				)}

				{data?.mapsTo && data.mapsTo.length > 0 && (
					<MapsToTable mapsTo={data.mapsTo} />
				)}
			</Wrapper>
		</SidebarContent>
	)
}

export default SnomedResults
