import { PagingInfo, ResultsPerPage } from '@elastic/react-search-ui'
import { PagingInfoView, ResultsPerPageView } from 'components/common'
import { Col } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { MetaBarWrapper } from './style'

const MetaInfoBar = () => {
	return (
		<MetaBarWrapper
			className='align-items-end position-sticky pt-1'
			style={{
				top: 0,
			}}
			id='result-meta-bar'
		>
			<Col className='p-0'>
				<PagingInfo view={PagingInfoView} />
			</Col>

			<Col xs='auto' className='p-0'>
				<ResultsPerPage
					view={ResultsPerPageView}
					options={[10, 15, 20, 30, 40, 50, 100]}
				/>
			</Col>
		</MetaBarWrapper>
	)
}

export default MetaInfoBar
