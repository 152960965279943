import { ListGroup } from 'react-bootstrap'
import { css, styled } from 'styled-components'
import type { DefaultTheme } from 'styled-components/dist/types'
interface IProps {
	$internalVariant?: string
	$color?: string
}

const variants = (theme: DefaultTheme, internalVariant = 'linkgroup') =>
	({
		linkgroup: css`
			.list-group-item-action {
				padding: 0.8rem;
				&:hover {
					text-decoration: underline;
					border-radius: 5px;
				}
			}
			svg {
				color: ${(props) => props.theme.colours.primary.blue};
			}
			.text-gray-color {
				color: #4b5156;
			}
		`,
		header: css`
			.list-group-item {
				border-color: white;
				border-bottom-width: 2px;
				transition: 0.2s ease;
				&:hover {
					border-color: ${(props) => props.theme.colours.primary.blue};
				}
			}
		`,
	})[internalVariant]

export const ListGroupWrapper = styled(ListGroup)<IProps>`
	font-size: 18px;

	.text-blue {
		color: ${(props) => props.theme.colours.primary.blue};
	}

	.bg-yellow {
		background-color: ${(props) => props.theme.colours.secondary.lightYellow};
	}

	.coming-soon {
		color: #6c757d;
		cursor: not-allowed;
	}

	${({ theme, $internalVariant }) => variants(theme, $internalVariant)}
`
