import { styled } from 'styled-components'

const SidebarFilterWrapper = styled.div<{ $isDesktop: boolean }>`
	position: sticky;
	top: 0;
	overflow-y: ${({ $isDesktop }) => ($isDesktop ? 'auto' : 'unset')};
	// height: ${(props) => (props.$isDesktop ? 'calc(100vh - 180px)' : 'unset')};
	// padding-bottom: ${(props) => (props.$isDesktop ? '5rem' : 'unset')};
`

export default SidebarFilterWrapper
