import { Col } from 'react-bootstrap'
import { Tag, Heading } from 'components/common'
import { codesystemsEndpointName, getApiEndpoint } from 'utils'
import { useTranslation } from 'react-i18next'
import { getHeadingVariant } from '../utils'
import { ContentProps, ICPC2BData } from './interface'
import { Wrapper } from './style'
import { useQuery } from '@tanstack/react-query'
import { Fragment, useState } from 'react'
import Accordion from 'components/common/elements/Accordion'
import parse, { type HTMLReactParserOptions, Element } from 'html-react-parser'
import { replaceLink } from '../../utils'
import ParseContent from './ParseContent'
import { useStoreContext } from 'context/StoreProvider'
import { isMobile } from 'react-device-detect'
import { chapterString } from 'utils'
import { useNavigate } from 'react-router-dom'

const Content = ({
	paragraph,
	codeSystem,
	innerRef,
	activeCode,
}: ContentProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [shouldFetch, setShouldFetch] = useState<boolean>(false)

	const { actions } = useStoreContext()
	const { updateCodeValue } = actions

	const handleNavigate = (codeValue: string) => {
		updateCodeValue(codeValue)
		navigate(`${chapterString}/${codeValue}/`)
	}

	/* The values not used on the page is commented out */
	const {
		codeValue,
		nameNorwegian,
		//nameEnglish,
		//genderSpecificCode,
		starCode,
		additionalCode,
		reportableNpr,
		//additionalAtcCodeRecommended,
		//nonSpecificCode,
		//compactCode,
		textMax60,
		//parentCode,
		active,
		//statusChangedDate,
		codeTip,
		notes,
		definition,
		//subterm,
		inclusion,
		exclusion,
		swordcodes,
		hierarchyLevel,

		/* ICPC2 spesifikk */
		moreInfo,
		//isEnrichedCode,

		/* NKPK spesifikk */
		//sourceCodeSystem,

		/* NORPAT spesifikk */
		replacedBy,
		isApat,
		validFrom,
		validTo,
	} = paragraph

	const options: HTMLReactParserOptions = {
		replace: (domNode) => replaceLink(domNode as Element, codeSystem),
	}
	const isMobileOrSmallScreen = isMobile || window.innerWidth < 992
	const fetchChapters = async () => {
		const res = await fetch(
			getApiEndpoint('ICPC2', codeValue as string, {
				useChildren: true,
				includeIcpc2b: true,
			})
		)
		return res.json()
	}

	/* This is spesific to ICPC-2 and ICPC-2B, to get the icpc2b codes */
	const { data: icpc2bData } = useQuery<ICPC2BData, Error>({
		queryKey: ['icpc2bData', codeValue],
		queryFn: fetchChapters,
		enabled: codeSystem === codesystemsEndpointName.icpc2 && shouldFetch,
		staleTime: 60000, // cache data for 1 minute
	})

	const accordionData = [
		{
			headerTitle: t('general.moreInfo'),
			body: moreInfo && parse(moreInfo, options),
		},
		{
			headerTitle: t('icpc2Page.icpc2bCodes'),
			onClick: () => setShouldFetch(true),
			body:
				Array.isArray(icpc2bData?.children) &&
				codeValue === icpc2bData?.codeValue &&
				icpc2bData?.children.map((item, index) => {
					return (
						<Fragment key={item.sortIndex}>
							<span className='fw-bold'>{item.codeValue}</span>{' '}
							<span>
								{item.nameNorwegian}
								{/* Don't include comma behind the last child */}
								{index + 1 !== icpc2bData.children.length ? ',' : null}{' '}
							</span>
						</Fragment>
					)
				}),
		},
	]

	/* NORPAT specific */
	// NORPAT codes that are structural codes do not have a number in the codeValue
	const isNorpatStructuralCode = () => {
		return (
			!/\d/.test(codeValue) && // Returns true if the codeValue contains a number
			codeSystem === codesystemsEndpointName.norpat
		)
	}

	return (
		<Wrapper
			key={codeValue}
			$hierarchyLevel={hierarchyLevel}
			id={codeValue}
			ref={innerRef}
			$activeCode={activeCode}
			className='d-flex flex-row gap-2'
			tabIndex={0}
		>
			<Col as='section' xs='auto' className='p-0'>
				<Heading
					id='chapter-code-heading'
					variant={getHeadingVariant(hierarchyLevel)}
					className='fw-bold heading-style'
					onClick={() => handleNavigate(codeValue)}
					style={{ cursor: 'pointer' }}
				>
					{codeValue}
				</Heading>
			</Col>
			<Col as='article' className='d-flex flex-column gap-2 p-0'>
				<Heading
					id='chapter-name-norwegian-heading'
					variant={getHeadingVariant(hierarchyLevel)}
					className='d-flex flex-row justify-content-between fw-bold heading-style'
					onClick={() => handleNavigate(codeValue)}
					style={{ cursor: 'pointer' }}
				>
					{nameNorwegian}
					{!isMobileOrSmallScreen && (
						<div className='d-flex justify-content-end gap-2 flex-wrap'>
							{additionalCode && (
								<Tag color='yellow' text={t('resultPage.additionalCode')} />
							)}
							{starCode && (
								<Tag color='yellow' text={t('resultPage.starCode')} />
							)}
							{reportableNpr && (
								<Tag color='yellow' text={t('resultPage.reportableNpr')} />
							)}
						</div>
					)}

					{(codeSystem === codesystemsEndpointName.norpat || !active) && (
						<Col xs='auto'>
							<Tag
								color={active ? 'green' : 'red'}
								text={active ? t('general.active') : t('general.inactive')}
							/>
						</Col>
					)}
				</Heading>
				{isMobileOrSmallScreen && (
					<div className='d-flex justify-content-end gap-2 ms-auto flex-wrap'>
						{additionalCode && (
							<Tag color='yellow' text={t('resultPage.additionalCode')} />
						)}
						{starCode && <Tag color='yellow' text={t('resultPage.starCode')} />}
						{reportableNpr && (
							<Tag color='yellow' text={t('resultPage.reportableNpr')} />
						)}
					</div>
				)}

				{/* The following code should be a map or similar, but
							since translation isn't very happy with using it outside
							React Components, and the types never match when 
							using it as props, this is an ok soulution for now */}

				{definition && <ParseContent content={definition} />}

				{codeSystem === codesystemsEndpointName.norpat && textMax60 && (
					<ParseContent content={textMax60} />
				)}

				{inclusion && (
					<ParseContent
						headline={t('resultPage.inclusion')}
						content={inclusion}
					/>
				)}
				{exclusion && (
					<ParseContent
						headline={t('resultPage.exclusion')}
						content={exclusion}
					/>
				)}
				{replacedBy && (
					<ParseContent
						headline={t('general.replacedBy')}
						content={replacedBy}
					/>
				)}
				{validFrom && !isNorpatStructuralCode() && (
					<ParseContent
						headline={t('general.validFrom')}
						content={validFrom}
						isDate
					/>
				)}
				{validTo && (
					<ParseContent
						headline={t('general.validTo')}
						content={validTo}
						isDate
					/>
				)}
				{isApat && <Tag color='yellow' text='APAT' />}
				{notes && (
					<ParseContent headline={t('resultPage.notes')} content={notes} />
				)}
				{codeTip && (
					<ParseContent headline={t('resultPage.codeTip')} content={codeTip} />
				)}
				{swordcodes && (
					<ParseContent
						headline={t('resultPage.swordCodes')}
						content={swordcodes}
					/>
				)}

				{codeSystem === codesystemsEndpointName.icpc2 &&
					hierarchyLevel > 1 &&
					moreInfo && <Accordion data={accordionData} />}
			</Col>
		</Wrapper>
	)
}

export default Content
