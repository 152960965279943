import { SearchProvider, SearchBox, WithSearch } from '@elastic/react-search-ui'
import { useTranslation } from 'react-i18next'
import { getCodeSystem, paths, resultsPerPage } from 'utils'
import { useLocation } from 'react-router-dom'
import { useStoreContext } from 'context/StoreProvider'
import { useLayoutEffect } from 'react'
import { SearchInput } from '../common'
import { Col, Row } from 'react-bootstrap'
import { ElasticWrapper } from 'components/common'
import { generalConfig } from './config'

const ElasticSearch = () => {
	const { t } = useTranslation()

	const { pathname } = useLocation()

	const { actions } = useStoreContext()

	const { updateCodeSystem } = actions

	const isFrontPage = pathname === paths.main

	useLayoutEffect(() => {
		updateCodeSystem(getCodeSystem(pathname) as string)
	}, [pathname, updateCodeSystem])

	return (
		<SearchProvider
			config={{
				...generalConfig,
				initialState: {
					resultsPerPage: resultsPerPage,
				},
			}}
		>
			<WithSearch
				mapContextToProps={({ error }) => ({
					error,
				})}
			>
				{({ error }) => {
					return (
						<>
							<ElasticWrapper
								$isFrontpage={isFrontPage}
								className='d-flex flex-column gap-3'
							>
								{pathname === paths.main && (
									<p
										className='pb-0 text-center'
										style={{ maxWidth: '800px', margin: 'auto' }}
									>
										{/* {t('frontpage.preamble')} */}
									</p>
								)}

								<Row className='flex-column flex-lg-row gap-2 align-content-center'>
									<Col className='p-0'>
										<SearchBox
											searchAsYouType
											shouldClearFilters={false}
											debounceLength={500}
											inputView={SearchInput}
										/>
									</Col>
								</Row>

								{error && (
									<div className='mt-3 text-danger'>
										{t('frontpage.search.error')}
									</div>
								)}
							</ElasticWrapper>
						</>
					)
				}}
			</WithSearch>
		</SearchProvider>
	)
}

export default ElasticSearch
