import { Row } from 'react-bootstrap'
import { styled } from 'styled-components'

export const MetaBarWrapper = styled(Row)<{ $topValue: string }>`
	align-items: end;
	position: sticky;
	z-index: 2;
	background-color: ${(props) => props.theme.colours.primary.white};
	border-bottom: 1px solid ${(props) => props.theme.colours.primary.greyShade};
`
