import { Row } from 'react-bootstrap'
import { styled } from 'styled-components'

export const TabWrapper = styled(Row)<{ $topValue: string }>`
	.tab-bar {
		border-bottom: 1px solid ${(props) => props.theme.colours.primary.greyShade};
		position: sticky;
	}

	.nav {
		border-color: ${(props) => props.theme.colours.primary.greyShade};

		.nav-link {
			color: ${(props) => props.theme.colours.primary.black};
			&:hover {
				color: ${(props) => props.theme.colours.primary.blue};
				border: unset;
			}
		}

		.active {
			color: ${(props) => props.theme.colours.primary.blue};
			border: unset;
			border-bottom: 2px solid ${(props) => props.theme.colours.primary.blue};
			&:hover {
				border-bottom: 2px solid ${(props) => props.theme.colours.primary.blue};
			}
		}
	}
`
