import { useQuery } from '@tanstack/react-query'
import { useStoreContext } from 'context/StoreProvider'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { collectionsString, endpoints } from 'utils'
import Node from './Node'
import { ErrorMessage, Loading } from 'components/common'
import { t } from 'i18next'

export interface AdmCollectionResponse {
	id: string
	name: string
	members: AdmCollectionResponse[]
}

const HierarchyAdm = () => {
	const navigate = useNavigate()
	const scrollRef = useRef<{ [key: string]: HTMLDivElement | null }>({})

	const { state, actions } = useStoreContext()

	const { code } = state
	const { updateCodeValue } = actions

	const [fetchError, setFetchError] = useState<string>('')

	const [expandedNodes, setExpandedNodes] = useState<string[]>([])

	/* Kan ikke bruke fetchApi, fordi hierarkiet har en ektra condition om å hente root dersom den får error */
	const fetchHierarchy = async () => {
		try {
			const res = await fetch(endpoints.apiAdmCollectionsEndpointDev)

			if (!res.ok && res.status !== 404) {
				setFetchError(res.statusText)
			} else {
				return await res.json()
			}
		} catch (err) {
			throw err
		}
	}
	const {
		data: hierachyAdm,
		isLoading,
		isError,
	} = useQuery<AdmCollectionResponse[], Error>({
		queryKey: [`adm-hierarchy`],
		queryFn: fetchHierarchy,
		retry: false,
	})

	useEffect(() => {
		if (code) {
			setExpandedNodes((prevExpandedNodes) => {
				if (!prevExpandedNodes.includes(code)) {
					return [...prevExpandedNodes, code]
				} else {
					return prevExpandedNodes
				}
			})

			// If hierachyAdm is loaded, find the parent of the node with id === code and add it to expandedNodes
			if (hierachyAdm) {
				hierachyAdm.forEach((parent: AdmCollectionResponse) => {
					const hasActiveCode = parent.members.some(
						(child) => child.id === code
					)

					if (hasActiveCode && !expandedNodes.includes(parent.id)) {
						setExpandedNodes((prevExpandedNodes) => [
							...prevExpandedNodes,
							parent.id,
						])
					}
				})
			}
		}
	}, [code, hierachyAdm])

	const handleExpand = (parent: AdmCollectionResponse) => () => {
		setExpandedNodes((prevExpandedNodes) => {
			if (prevExpandedNodes.includes(parent.id)) {
				// If the node is already expanded, remove its ID from selectedParentIds
				return prevExpandedNodes.filter((id) => id !== parent.id)
			} else {
				// If the node is not expanded, add its ID to selectedParentIds
				return [...prevExpandedNodes, parent.id]
			}
		})
	}

	const handleNavigate = (item: AdmCollectionResponse) => {
		updateCodeValue(item.id as string)
		navigate(`${collectionsString}/${item.id}`)
	}

	useLayoutEffect(() => {
		const scrollToElement = () => {
			const element = scrollRef.current[code as string]
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'start' })
			}
		}
		scrollToElement()
	}, [code])

	return (
		<aside id='adm-hierarchy'>
			<div style={{ paddingBottom: '4rem' }}>
				{isError && <ErrorMessage errorResponse={fetchError} />}
				{isLoading && <Loading />}
				{Array.isArray(hierachyAdm) &&
					hierachyAdm
						.sort(function (a: { name: string }, b: { name: string }) {
							if (a?.name < b?.name) {
								return -1
							}
							if (a?.name > b?.name) {
								return 1
							}
							return 0
						})
						.map((parent: AdmCollectionResponse) => {
							const level = 0

							const hasActiveCode = parent.members.some(
								(child) => child.id === code
							)
							return (
								<Node
									key={parent.id}
									item={parent}
									isActive={hasActiveCode}
									isExpanded={expandedNodes.includes(parent.id)}
									handleNavigate={handleExpand(parent)}
									handleExpand={handleExpand(parent)}
									level={level}
									innerRef={(ref: HTMLDivElement | null) => {
										scrollRef.current[parent.id] = ref
									}}
								>
									{expandedNodes.includes(parent.id) &&
										parent.members &&
										parent.members
											.sort(
												(a: { id: string }, b: { id: string }) =>
													parseInt(a.id) - parseInt(b.id)
											)
											.map((child) => {
												return (
													<Node
														key={child.id}
														item={child}
														isExpanded={child.id === code}
														isActive={child.id === code}
														handleNavigate={() => handleNavigate(child)}
														level={level + 1}
														innerRef={(ref: HTMLDivElement | null) => {
															scrollRef.current[child.id] = ref
														}}
													/>
												)
											})}
								</Node>
							)
						})}
			</div>
		</aside>
	)
}

export default HierarchyAdm
