import { externalUrls } from 'utils'
import { OptionsDataProps } from '../interface'

const optionsData: OptionsDataProps = {
	icd10: {
		blueBook: 'icd10Page.blueBook',
		blueBookLink: externalUrls.blueBookIcd,
		codingGuide: 'icd10Page.codingGuide',
		codingGuideLink: externalUrls.codingGuideIcd,
		about: 'icd10Page.about',
		aboutLink: externalUrls.aboutIcd,
		downloadExcel: externalUrls.downloadExcelIcdTemp,
		api: true,
		report: `mailto:${externalUrls.reportErrorEmail}`,
		reportEmail: externalUrls.reportErrorEmail,
	},
	icpc2: {
		about: 'icpc2Page.about',
		aboutLink: externalUrls.aboutIcpc,
		downloadExcel: externalUrls.downloadExcelIcpcTemp,
		api: true,
		report: `mailto:${externalUrls.reportErrorEmail}`,
		reportEmail: externalUrls.reportErrorEmail,
	},
	nkpk: {
		codingGuide: 'nkpkPage.codingGuide',
		codingGuideLink: externalUrls.codingGuideNkpk,
		about: 'nkpkPage.about',
		aboutLink: externalUrls.aboutNkpk,
		downloadExcel: externalUrls.downloadExcelNkpkTemp,
		api: true,
		report: `mailto:${externalUrls.reportErrorEmail}`,
		reportEmail: externalUrls.reportErrorEmail,
	},
	phbu: {
		about: 'phbuPage.about',
		aboutLink: externalUrls.aboutPhbu,
		downloadExcel: externalUrls.downloadExcelPhbuTemp,
		api: true,
		report: `mailto:${externalUrls.reportErrorEmail}`,
		reportEmail: externalUrls.reportErrorEmail,
	},
	norpat: {
		about: 'norpatPage.about',
		aboutLink: externalUrls.aboutNorpat,
		api: true,
		report: `mailto:${externalUrls.reportErrorLab}`,
		reportEmail: externalUrls.reportErrorLab,
		hasExcelApiEndpoint: true,
	},
	anatomisk: {
		about: 'general.aboutLab',
		aboutLink: externalUrls.aboutLab,
		api: true,
		report: `mailto:${externalUrls.reportErrorLab}`,
		reportEmail: externalUrls.reportErrorLab,
		hasExcelApiEndpoint: true,
	},
	provemateriale: {
		about: 'general.aboutLab',
		aboutLink: externalUrls.aboutLab,
		api: true,
		report: `mailto:${externalUrls.reportErrorLab}`,
		reportEmail: externalUrls.reportErrorLab,
		hasExcelApiEndpoint: true,
	},
	tekstligeResultatverdier: {
		about: 'general.aboutLab',
		aboutLink: externalUrls.aboutLab,
		api: true,
		report: `mailto:${externalUrls.reportErrorLab}`,
		reportEmail: externalUrls.reportErrorLab,
		hasExcelApiEndpoint: true,
	},
	nlk: {
		about: 'general.aboutLab',
		aboutLink: externalUrls.aboutLab,
		api: true,
		report: `mailto:${externalUrls.reportErrorLab}`,
		reportEmail: externalUrls.reportErrorLab,
		hasExcelApiEndpoint: true,
	},
	undersokelsesmetode: {
		about: 'general.aboutLab',
		aboutLink: externalUrls.aboutLab,
		api: true,
		report: `mailto:${externalUrls.reportErrorLab}`,
		reportEmail: externalUrls.reportErrorLab,
		hasExcelApiEndpoint: true,
	},
	adm: {
		about: 'adminPage.about',
		aboutLink: externalUrls.aboutKis,
		api: true,
		report: `mailto:${externalUrls.reportErrorAdm}`,
		reportEmail: externalUrls.reportErrorAdm,
	},
	snomedct: {
		about: 'snomedctPage.about',
		aboutLink: externalUrls.aboutSnomedct,
		api: true,
		report: externalUrls.reportErrorTerminology,
	},
}

export default optionsData
