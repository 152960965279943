import { faBars, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStoreContext } from 'context/StoreProvider'
import {
	Container,
	Image,
	Nav,
	NavDropdown,
	Navbar,
	Offcanvas,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { paths } from 'utils'
import { HeaderWrapper, LogoStyle } from './style'
import { useState } from 'react'

const Header = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { actions } = useStoreContext()
	const { updateCodeValue } = actions
	const { pathname } = useLocation()

	const [expanded, setExpanded] = useState<boolean>(false)

	const isFrontPage = pathname === paths.main

	const handleExpanded = () => setExpanded(!expanded)

	const handleClickLink = (codesystem: string) => {
		/* Don't do anything if the path is the same */
		if (pathname.includes(codesystem)) {
			return null
		} else {
			setExpanded(false)
			navigate(codesystem)
			updateCodeValue('')
		}
	}

	return (
		<>
			<HeaderWrapper
				collapseOnSelect
				expand={'lg'}
				expanded={expanded}
				id='site-header'
			>
				<Container className={'py-1 px-xl-5 position-relative'}>
					<a href='#main-content' className='skip-link'>
						{t('accessibility.jumpToMainContent')}
					</a>
					<LogoStyle href={paths.main} className='p-0 m-0'>
						<Image
							src='/assets/finnkode-logo-blue.svg'
							alt='Finnkode logo'
							className='p-0 m-0'
							width={156}
						/>
					</LogoStyle>
					<Navbar.Toggle
						aria-controls='basic-navbar-nav'
						onClick={handleExpanded}
					>
						<FontAwesomeIcon icon={expanded ? faClose : faBars} size='sm' />
						<span>{t('general.menu')}</span>
					</Navbar.Toggle>
					<Navbar.Offcanvas id={`offcanvasNavbar-expand-lg`} placement='top'>
						<Offcanvas.Body>
							<Nav className='ms-auto gap-lg-0 gap-xl-2 gap-2'>
								<NavDropdown
									title={t('codesystems.clinicalCodework.title')}
									id='basic-nav-dropdown'
									align={'end'}
								>
									<NavDropdown.Item
										onClick={() => handleClickLink(paths.icd10)}
									>
										{t('codesystems.clinicalCodework.icd10.name')}
									</NavDropdown.Item>
									<NavDropdown.Item
										onClick={() => handleClickLink(paths.icpc2)}
									>
										{t('codesystems.clinicalCodework.icpc2.name')}
									</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleClickLink(paths.nkpk)}>
										{t('codesystems.clinicalCodework.nkpk.name')}
									</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleClickLink(paths.phbu)}>
										{t('codesystems.clinicalCodework.phbu.name')}
									</NavDropdown.Item>
								</NavDropdown>
								<NavDropdown
									title={t('codesystems.labCodework.title')}
									id='basic-nav-dropdown'
									align={'end'}
								>
									<NavDropdown.Item
										onClick={() => handleClickLink(paths.norpat)}
									>
										{t('codesystems.labCodework.norpat')}
									</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleClickLink(paths.nlk)}>
										{t('codesystems.labCodework.nlk')}
									</NavDropdown.Item>
									<NavDropdown.Item
										onClick={() => handleClickLink(paths.provemateriale)}
									>
										{t('codesystems.labCodework.provemateriale')}
									</NavDropdown.Item>
									<NavDropdown.Item
										onClick={() => handleClickLink(paths.anatomisk)}
									>
										{t('codesystems.labCodework.anatomisk')}
									</NavDropdown.Item>
									<NavDropdown.Item
										onClick={() =>
											handleClickLink(paths.tekstligeresultatverdier)
										}
									>
										{t('codesystems.labCodework.tekstligeresultatverdier')}
									</NavDropdown.Item>
									<NavDropdown.Item
										onClick={() => handleClickLink(paths.undersokelsesmetode)}
									>
										{t('codesystems.labCodework.undersokelsesmetode')}
									</NavDropdown.Item>
								</NavDropdown>
								<Nav.Link onClick={() => handleClickLink(paths.adm)}>
									{t('codesystems.other.standarder.name')}
								</Nav.Link>
								<Nav.Link onClick={() => handleClickLink(paths.snomed)}>
									{t('snomedctPage.name')}
								</Nav.Link>
							</Nav>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
				</Container>
			</HeaderWrapper>
		</>
	)
}

export default Header
