import { Paging, withSearch } from '@elastic/react-search-ui'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { Button, Loading } from 'components/common'
import { useEffect, useLayoutEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { codesystems, getCodeSystem, paths, scrollToTop } from 'utils'
import { useStoreContext } from 'context/StoreProvider'
import { useTranslation } from 'react-i18next'
import { BodyResultsProps } from './interface'
import {
	isClinicalCodesystem,
	isSmallLabCodesystem,
} from 'components/search/buckets/utils'
import SmallLabResultView from './common/SmallLabResultView'
import { isMobile } from 'react-device-detect'
import { ScrollWrapper, SidebarWrapper } from 'components/search/buckets/common'
import { MetaInfoBar, ResultView, SidebarFilter } from './common'
import { SidebarContent } from '../SearchResultLayout/style'

const BodyResults = ({
	searchTerm,
	resultSearchTerm,
	isLoading,
	setFilter,
	removeFilter,
	addFilter,
}: BodyResultsProps) => {
	const [showFilter, setShowFilter] = useState<boolean>(
		isMobile || window.innerWidth < 992 ? false : true
	)
	const { t } = useTranslation()

	const { state } = useStoreContext()

	const { inputRef, codeSystem } = state

	const { pathname } = useLocation()

	/* This checks if the searchTerm changes to something different than the first searchTerm, 
  so that the content updates with the result if the user searches for something else  */

	useLayoutEffect(() => {
		if (
			getCodeSystem(pathname) === codesystems.icpc2 &&
			pathname !== paths.main
		) {
			setFilter('code_system', codesystems.icpc2, 'any')
			addFilter('code_system', codesystems.icpc2b, 'any')
		}
		if (pathname.includes(paths.snomed)) {
			removeFilter('code_system')
		}
	}, [pathname, setFilter, codeSystem, removeFilter])

	/* This makes sure the input gets refocused after the searchTerm state update */
	useEffect(() => {
		if (inputRef?.current) {
			inputRef.current.focus()
		}
	}, [searchTerm])

	const isMobileOrSmallScreen = isMobile || window.innerWidth < 992

	return (
		<>
			<SidebarContent
				$size={
					isMobileOrSmallScreen || isClinicalCodesystem(pathname) ? 'sm' : 'md'
				}
			>
				<div>
					<Row className='row-lg-cols-2 position-relative'>
						{isMobileOrSmallScreen && (
							<Button
								variant='tertiary'
								icon={!showFilter ? faArrowDown : faArrowUp}
								className='justify-content-between d-flex mt-2 mt-lg-0'
								size='sm'
								iconright
								color='grey'
								onClick={() => setShowFilter(!showFilter)}
								textColor='black'
							>
								{!showFilter
									? t('nlkPage.filter.showFilter')
									: t('nlkPage.filter.hideFilter')}
							</Button>
						)}
						{showFilter && (
							<SidebarWrapper
								className={`ps-0 d-flex flex-column gap-3 pt-2 pb-2 pt-lg-0 pb-lg-0`}
								xs={12}
								lg={3}
							>
								<SidebarFilter searchTerm={searchTerm} />
							</SidebarWrapper>
						)}

						<Col
							className='p-0 position-relative bg-white' //neccessary?
							lg={9}
							id='search-results'
							tabIndex={0}
						>
							{isLoading && <Loading />}
							{!isLoading && <MetaInfoBar />}
							{isSmallLabCodesystem(pathname) && !isLoading && (
								<>
									<ScrollWrapper id='lab-result-wrapper'>
										<SmallLabResultView />
									</ScrollWrapper>
								</>
							)}
							{/* Fordi her skal ikke søkeresultatene vises før søk */}
							{!isSmallLabCodesystem(pathname) &&
								resultSearchTerm !== '' &&
								!isLoading && (
									<>
										<ScrollWrapper
											id='result-wrapper'
											$isAdm={pathname.startsWith(paths.adm)}
										>
											<ResultView />
										</ScrollWrapper>
										<Row onClick={scrollToTop} className='py-2'>
											<Paging />
										</Row>
									</>
								)}
						</Col>
					</Row>
				</div>
			</SidebarContent>
		</>
	)
}

export default withSearch(
	({
		searchTerm,
		resultSearchTerm,
		isLoading,
		setFilter,
		removeFilter,
		addFilter,
	}) => ({
		searchTerm,
		resultSearchTerm,
		isLoading,
		setFilter,
		removeFilter,
		addFilter,
	})
)(BodyResults)
